<template>
	<div class="grid p-fluid">
		<div class="col-12">
			<Button class="p-button-sm mr-1" icon="pi pi-sync" label="更新" @click="onRefreshButtonClick" :loading="loading" style="width: auto"/>
			<Menu ref="menu" :model="overlayMenuItems" :popup="true" />
			<!-- <Button class="p-button-sm" icon="pi pi-angle-down" :label="getMenuLabel()" @click="onToggleMenuClick" style="width: auto"/> -->
			<!-- <SplitButton icon="pi pi-sync" label="ニュース更新" :model="button_items" class="p-button-sm mr-1 mb-1" @click="onRefreshButtonClick" :loading="loading"/> -->
			<div class="card card-w-title">
				perf {{ perf }}
        <TabMenu :model="nestedRouteItems"/>
        <router-view :newsParam="newsParam" :dataviewValue="dataviewValue" @refreshNewsData="refreshNewsData" @setCurrentTabID="setCurrentTabID"/>
			</div>
		</div>
	</div>
</template>

<script>
import NewsListService from "@/service/news/NewsListService";

export default {
	data() {
		return {
			perf: null,
			key1: true,
			key2: 'foo',
			menuItems: ['Recent', 'All'],
			currentMenuID: 0,
			currentTabID: 0,
			loading: false,
			dataviewValue: null,
			newsParam: {
				mode: 'r', // a(all = meigaranews2 table), r(recent = v_meigaranews2_recent view), 
				type: 'a', // a(all news types), m(meigara), c(category), t(tags), s(sentiment), o(one individual news) 
				key: '',   // key string to search news
				code: 'ra',  // mode + type + key
			},
			nestedRouteItems: [
				{label: '銘柄ニュース一覧', to: '/news/ra'},
				{label: '銘柄ニューストレンド', to: '/news/ra/trend'}
			],
			overlayMenuItems: [
				{
					label: 'Recent',
					command: () => {
						this.currentMenuID = 0;
						this.newsParam.mode = 'r';
						// this.switchNewsMode('r');
					}               
				},
				{
					label: 'All',
					command: () => {
						this.currentMenuID = 1;
						this.newsParam.mode = 'a';
						// this.switchNewsMode('a');
					}               
				},
			]
		}
	},
	newsListService: null,
	created() {
		this.newsListService = new NewsListService();
	},
	mounted() {
		console.log('News.vue mounted');

		let code = this.$route.params.code;

		this.nestedRouteItems[0].to = '/news/' + code;
		this.nestedRouteItems[1].to = '/news/' + code + '/trend';

		this.newsParam.mode = code.substring(0, 1);
		this.newsParam.type = code.substring(1, 2);
		this.newsParam.key = code.substring(2, code.length);
		this.newsParam.code = code;
		// console.log('News.vue - mounted():' + this.newsParam.code);
	},
	methods: {
		getMenuLabel() {
			return this.menuItems[this.currentMenuID];
		},
		onToggleMenuClick(event) {
			this.$refs.menu.toggle(event);
		},
		onRefreshButtonClick() {
			this.refreshNewsData(this.newsParam.code);
		},
		refreshNewsData(code) {
			this.loading = true;
			this.newsListService.getNewsInfo(code)
				.then(d => {
					this.dataviewValue = d.data.newslist;
					this.perf = d.perf;
					this.newsData = d.data;
					this.loading = false;

					console.log('newsData refreshed');
				});
		},
		setCurrentTabID(id) {
			this.currentTabID = id;
		},
		switchNewsMode(mode){
			this.newsParam.mode = mode;
			this.newsParam.code = this.newsParam.mode + this.newsParam.type + this.newsParam.key;
			let path = '/news/' + this.newsParam.code;
			if(this.currentTabID == 1) {
				path += '/trend';
			}
			this.$router.push(path);
		}
	}
}
</script>

<style scoped lang="scss">
</style>
